<template>
  <section v-if="loading" class="loading">
    <p>
      Een moment geduld alstublieft, de pagina wordt geladen.
    </p>
  </section>
  <section v-else class="player-page">
    <Header :broadcast="broadcast" :currentBrand="currentBrand" />
    <b-container>
      <h1>{{ broadcast.name }}</h1>
      <transition name="fade">
        <b-row cols="12" v-if="message" id="message">
          <b-col cols="12" v-html="message" class="mt-3"/>
        </b-row>
      </transition>
      <b-col class="justify-content-center" v-if="token">
        <p v-if="videoExpired()">
          Download is verlopen op {{ expiresAtDay() }}.
        </p>
        <div v-else>
          <p>De uitzending is te downloaden tot {{ expiresAtDay() }}.</p>
          <b-form class="justify-content-center" inline>
            <b-form-select v-model="selected" :options="sortedFiles" class="mb-2 mr-sm-2 mb-sm-0">
            </b-form-select>
            <b-button v-on:click="doDownload" variant="primary" >Download</b-button>
          </b-form>
        </div>
      </b-col>
      <div v-else>
        U heeft geen geldige download URL gekregen.
      </div>
    </b-container>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import prettyBytes from 'pretty-bytes';
import Broadcast from '../models/Broadcast';
import Header from '../components/Header.vue';
import currentBrandMixin from '../mixins/currentBrandMixin';
import domainChecker from '../mixins/domainChecker';
import messageMixin from '../mixins/messageMixin';
import broadcastHelperMixin from '../mixins/broadcastHelperMixin';

export default {
  mixins: [
    currentBrandMixin,
    domainChecker,
    messageMixin,
    broadcastHelperMixin,
  ],
  components: {
    Header,
  },
  data() {
    return {
      broadcast: {
        name: '',
      },
      loading: true,
      error: null,
      errored: false,
      invalid: false,
      showClickToStartMessage: true,
      playing: false,
      timer: null,
      shareId: null,
      playerId: this.$cookies.get('playerId'),
      token: this.$route.query.token,
      selected: null,
    };
  },
  methods: {
    retrieveBroadcast() {
      Broadcast.extraParams({ token: this.token })
        .where({ shareId: this.discoverShareId() }).all()
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.broadcast = response.data[0];
          this.errored = false;
          this.error = null;
        })
        .then(() => {
          if (this.broadcast && this.broadcast.downloads && this.sortedFiles.length > 0) {
            this.selected = this.sortedFiles[0].value;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.errored = true;
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
          this.requestPending = false;
        });
    },
    videoExpired() {
      return new Date(this.broadcast.expiresAt) < new Date();
    },
    expiresAtDay() {
      return format(new Date(this.broadcast.expiresAt), 'd MMMM yyyy', { locale: nl }); // 25 november 2020
    },
    doDownload() {
      window.open(this.selected, '_blank');
    },
    lookupFriendlyName(name) {
      return {
        'high.mp4': 'Hoge kwaliteit',
        'medium.mp4': 'Gemiddelde kwaliteit',
        'low.mp4': 'Lage kwaliteit',
      }[name];
    },
    compare(a, b) {
      if (a.filesize < b.filesize) return 1;
      if (a.filesize > b.filesize) return -1;
      return 0;
    },
  },
  mounted() {
    this.retrieveBroadcast();
  },
  computed: {
    sortedFiles() {
      if (this.broadcast.downloads) {
        const { files } = this.broadcast.downloads;
        return files.sort(this.compare).map((file) => ({
          text: `${this.lookupFriendlyName(file.name)} (${prettyBytes(file.filesize)})`,
          value: file.download_url,
        }));
      }
      return [];
    },
  },
};
</script>

<style>

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
    border: 1px solid  #007bff;
}
</style>
